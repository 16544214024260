import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import RectangleImage from "../assets/nyscImg.png";
import Logo from "../assets/iquireLogo.png";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import "./login.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import RegisterSuccessful from "../components/modals/RegisterSuccessful";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo2 from "../assets/blackLogo.png";
import Logo3 from "../assets/newLogoIquire.png";
import "./getStarted.css";
import { createUser, uploadCvApi } from "../services";
import { notify } from "../components/actions/CommonActions";
import ApplicationSuccessful from "../components/modals/ApplicationSuccessful";

const IEESPNScholarshipRegister = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [createResponse, setCreateResponse] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [loadCountry, setLoadCountry] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const LoginSchema = Yup.object().shape({
    // password: Yup.string().min(8, "Too Short!").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    phone: Yup.string().required("Required"),
    batch: Yup.string().required("Required"),
    nysc_id: Yup.string().required("Required"),
    state_of_service: Yup.string().required("Required"),
  });


  const data = [
    {
      id: 1,
      text: "Share your knowledge and expertise with learners from all over the world and earn while at it",
      name: "Henry Ninth",
      job: "Product Designer, iQuire",
    },
    {
      id: 2,
      text: "Share your knowledge and expertise with learners from all over the world and earn while at it",
      name: "AJ",
      job: "CEO, iQuire",
    },
    {
      id: 3,
      text: "Share your knowledge and expertise with learners from all over the world and earn while at it",
      name: "Olamide Sanusi",
      job: "Product Manager, iQuire",
    },
  ];

  return (
    <div>
      {loadCountry ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            minHeight: "100vh",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Grid
          item
          container
          spacing={2}
          sx={{
            [theme.breakpoints.down("md")]: {
              width: "100%", // Take full width on small screens
              display: "flex",
              justifyContent: "center", // Optional: Ensure content is centered
              alignItems: "center",
              minHeight: "100vh",
            },
          }}
        >
          {!isSmallScreen && (
            <Grid
              item
              xs={6}
              md={6}
              sx={{
                backgroundImage: `url(${RectangleImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "103vh",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                paddingBottom: "20px",
              }}
            >
              <a href='https://www.iquire.co/'><img src={Logo3} className="logoImg" /></a>
              {/* <div className='imgLeftAdView'>
            <div className='adHolderDiv'>
              <div className='reactIconDiv'>
              <BiSolidMessageAltDetail color='white' size={22} />
              </div>
              <p className='AdText'>Share your knowledge and expertise with learners from all over the world and earn while at it</p>
              <p className='adTextOwner'>Henry Ninth</p>
              <p className='adTextOccupation'>Product Designer, Google</p>
            </div>
          </div> */}
              <Swiper
                pagination={true}
                modules={[Pagination]}
                className="mySwiper"
              >
                {data?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="adHolderDiv">
                      <div className="reactIconDiv">
                        <BiSolidMessageAltDetail color="white" size={22} />
                      </div>
                      <p className="AdText">{item?.text}</p>
                      <p className="adTextOwner">{item?.name}</p>
                      <p className="adTextOccupation">{item?.job}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Grid>
          )}
          <Grid
            item
            xs={6}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                width: "100%", // Take full width on small screens
                display: "flex",
                justifyContent: "center", // Optional: Ensure content is centered
                alignItems: "center",
              },
            }}
          >
            <Formik
              initialValues={{
                email: "",
                first_name: "",
                last_name: "",
                phone: "",
                batch: "",
                nysc_id: "",
                state_of_service: ""
              }}
              validationSchema={LoginSchema}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setLoading(true);
                // await createUser(values)
                // console.log("register nysc ", values);
                let REACT_APP_X_API_KEY = "jfnfnfkslIIIDNKffnkmd9039jjsldnduzsaiieu8859%^&*nsjASFB0)@"
                await fetch(`https://bk.iquire.co/api/v1/iquire/ieespn/register`, {
                  method: 'POST',
                  headers:{
                    'Content-Type': 'application/json',
                    'x-api-key' : "jfnfnfkslIIIDNKffnkmd9039jjsldnduzsaiieu8859%^&*nsjASFB0)@",
                },
                body: JSON.stringify(values)
                })
                .then((res)=> res.json())
                  .then((res) => {
                    // console.log('res ',res);
                    setCreateResponse(res?.data);
                    setOpen(true);
                    resetForm()
                  })
                  .catch((error) => {
                    console.log("error create ", error);
                    notify("danger", "OOPS", error?.response?.data?.message);
                  });
                setLoading(false);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form className="inputFieldView" onSubmit={handleSubmit}>
                  <div className="imageHolderLogoGetStarted">
                  <a href='https://www.iquire.co/'>
                    <img
                      src={Logo2}
                      style={{ marginTop: "20px" }}
                      className="logoImgGetStarted"
                    />
                    </a>
                  </div>
                  <h1 className="getStartedTopic">Apply for IEESPN </h1>
                  <p className="adTextOccupation">
                  Fill in your details below
                  </p>


                  <div className="selectHolderGetStarted">
                    <div className="selectDivPickGetStarted">
                  <p className="inputLabelText" style={{ marginTop: "24px" }}>
                    First name<span style={{ color: "red" }}>*</span>
                  </p>

                  <input
                    name="first_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    type="text"
                    className="emailSelectText"
                    style={{padding: "15px 5px"}}
                    placeholder="Henry"
                  />
                  {errors.first_name && touched.first_name && errors.first_name && (
                    <p className="errorLogin">{errors.first_name}</p>
                  )}
</div>
<div className="selectDivPickGetStarted">
                  <p className="inputLabelText" style={{ marginTop: "24px" }}>
                    Last name<span style={{ color: "red" }}>*</span>
                  </p>

                  <input
                    name="last_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    type="text"
                    className="emailSelectText"
                    style={{padding: "15px 5px"}}
                    placeholder="Henry"
                  />
                  {errors.last_name && touched.last_name && errors.last_name && (
                    <p className="errorLogin">{errors.last_name}</p>
                  )}
                  </div>
                  </div>

                  <p className="inputLabelText" style={{ marginTop: "24px" }}>
                    Email Address<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    type="email"
                    className="emailInputText"
                    placeholder="example@email.com"
                  />
                  {errors.email && touched.email && errors.email && (
                    <p className="errorLogin">{errors.email}</p>
                  )}

                  <p className="inputLabelText" style={{ marginTop: "24px" }}>
                    Phone number<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    type="text"
                    className="emailInputText"
                    placeholder="08112223334445"
                  />
                  {errors.phone &&
                    touched.phone &&
                    errors.phone && (
                      <p className="errorLogin">{errors.phone}</p>
                    )}

                  

<p className="inputLabelText" style={{ marginTop: "24px" }}>
                    NYSC ID Number<span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    name="nysc_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.nysc_id}
                    type="text"
                    className="emailInputText"
                    placeholder="*****"
                  />
                  {errors.nysc_id &&
                    touched.nysc_id &&
                    errors.nysc_id && (
                      <p className="errorLogin">{errors.nysc_id}</p>
                    )}

<div className="selectHolderGetStarted">
                    <div className="selectDivPickGetStarted">
                  <p className="inputLabelText" style={{ marginTop: "24px" }}>
                    Batch<span style={{ color: "red" }}>*</span>
                  </p>

                  <input
                    name="batch"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.batch}
                    type="text"
                    className="emailSelectText"
                    style={{padding: "15px 5px"}}
                    placeholder="Henry"
                  />
                  {errors.batch && touched.batch && errors.batch && (
                    <p className="errorLogin">{errors.batch}</p>
                  )}
</div>
<div className="selectDivPickGetStarted">
                  <p className="inputLabelText" style={{ marginTop: "24px" }}>
                    State of Service<span style={{ color: "red" }}>*</span>
                  </p>

                  <input
                    name="state_of_service"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state_of_service}
                    type="text"
                    className="emailSelectText"
                    style={{padding: "15px 5px"}}
                    placeholder="Henry"
                  />
                  {errors.state_of_service && touched.state_of_service && errors.state_of_service && (
                    <p className="errorLogin">{errors.state_of_service}</p>
                  )}
                  </div>
                  </div>

                  <button
                    className="loginButton"
                    type="submit"
                    //   disabled={isSubmitting}
                  >
                    {loading ? "Loading..." : "Apply now"}
                  </button>
                </form>
              )}
            </Formik>

            <ApplicationSuccessful
              open={open}
              setOpen={setOpen}
              createResponse={createResponse}
              linkTo="https://t.me/+UTk3Cbp6gYEzMmY0"
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default IEESPNScholarshipRegister;
